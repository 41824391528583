exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-e-bikes-js": () => import("./../../../src/pages/e-bikes.js" /* webpackChunkName: "component---src-pages-e-bikes-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leasing-js": () => import("./../../../src/pages/leasing.js" /* webpackChunkName: "component---src-pages-leasing-js" */),
  "component---src-pages-probefahrten-js": () => import("./../../../src/pages/probefahrten.js" /* webpackChunkName: "component---src-pages-probefahrten-js" */),
  "component---src-pages-reparatur-js": () => import("./../../../src/pages/reparatur.js" /* webpackChunkName: "component---src-pages-reparatur-js" */)
}

